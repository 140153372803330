import ExcelImportDialog from './../features/create_documents/ExcelImportDialog.vue'
import ExcelExportDialog from './../features/create_documents/ExcelExportDialog.vue'
import AICreateFromDocDialog from './../features/create_documents/AICreateFromDocDialog.vue'
import AICreateFromTextDialog from './../features/create_documents/AICreateFromTextDialog.vue'
import { type ExposeStimulus, StimulusControllerBase } from './base_controller'
import { useUserStore } from '@stores/generic/user.store'
import { getActivePinia } from 'pinia'
import type { Component } from 'vue'
import { useErrorToast } from '@component-utils/toasts'
import TemplatesApi from '~/api/TemplatesApi'

export interface ExcelTemplatesController extends ExposeStimulus<typeof ExcelTemplatesController> {}
export class ExcelTemplatesController extends StimulusControllerBase {
  static values = {
    template: String,
    templateVersionId: String
  }

  userStore = useUserStore(getActivePinia())

  async excel_import() {
    await this._callDialog(ExcelImportDialog as Component, false, true, true)
  }

  async excel_export() {
    await this._callDialog(ExcelExportDialog as Component, false, false, true)
  }

  async ai_create_from_doc() {
    await this._callDialog(AICreateFromDocDialog as Component, true, true, false)
  }

  async ai_create_from_text() {
    await this._callDialog(AICreateFromTextDialog as Component, true, true, false)
  }

  async _callDialog(dialog: Component, forSingleTemplate: boolean, toHydrateUserStore: boolean, useTemplateVersionIds: boolean) {
    if (toHydrateUserStore) await this.userStore.hydrate({}, ['is_open_ai_enabled'])
    const templates = this._selectedTemplates().concat(await this.#selectedFolders())
    if (templates.length > 1 && forSingleTemplate) return
    window.avv_dialog({
      vueDialog: dialog,
      callback: () => {},
      props: useTemplateVersionIds ? {
        templateVersionIds: this.#getSelectedTemplateVersionIds()
      } : {
        templates
      }
    })
  }

  #getSelectedTemplateVersionIds () {
    if (this.hasTemplateVersionIdValue) {
      return [Number(this.templateVersionIdValue)]
    } else {
      const form = document.getElementById('templates-form')!
      const checkboxes = form.querySelectorAll<HTMLInputElement>('.templates-item-check-input')

      return Array.from(checkboxes)
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => Number(checkbox.dataset.templateVersionId))
    }
  }

  _selectedTemplates(): string[] {
    if (this.templateValue) {
      return [this.templateValue]
    } else {
      const form = document.getElementById('templates-form')!
      const checkboxes = form.querySelectorAll<HTMLInputElement>('.templates-item-check-input')
      return Array.from(checkboxes)
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value)
    }
  }

  #selectedFolders() {
    const checkboxes = Array.from(document.querySelectorAll<HTMLInputElement>('.folders-item-check-input'))
    const folderIds = checkboxes.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value)
    return this.templateIdsInFolders(folderIds)
  }

  async templateIdsInFolders (folderIds: string[]) {
    if (folderIds.length === 0) return []
    try {
      const { templates } = await TemplatesApi.folderTemplates<{ templates: Pick<Backend.Models.Template, 'id' | 'current_version_id'>[] }>({ data: { folder_ids: folderIds, published: true } })

      return templates.map((template) => String(template.id))
    } catch (e) {
      useErrorToast(e)

      return []
    }
  }
}

export default ExcelTemplatesController
